@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.main a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.main a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.main a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.main table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

.main table,
.main td,
.main tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.main td {
  padding: var(--sk-space-8);
}

/* HTML sized tags */
.richTextIframe,
.richTextImg {
  max-width: 100%;
}

.richTextImg {
  border-radius: var(--sk-radius-4);
}

/* FROALA RICH TEXT */
/* https://github.com/jobteaser/ui-jobteaser/blob/5a878d7153c33420e90d81585c6ee69e9a6e38a8/web_app/src/shared_spa/components/elements/FroalaRichText/FroalaRichText.module.css */

/* Images */
.froala :global .fr-dib,
.froala :global .fr-dii {
  max-width: 100%;
  height: auto !important;
}

.froala :global .fr-dib {
  display: block;
  margin: var(--sk-space-4) auto;
}

.froala :global .fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.froala :global .fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.froala :global .fr-img-caption .fr-img-wrap {
  display: inline-block;
  max-width: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
}

.froala :global .fr-dii {
  display: inline-block;
  max-width: calc(100% - (2 * var(--sk-space-4)));
  margin-right: var(--sk-space-4);
  margin-left: var(--sk-space-4);
  float: none;
  vertical-align: bottom;
}

.froala :global .fr-dii.fr-fil {
  max-width: 66%;
  margin: var(--sk-space-12) var(--sk-space-16) var(--sk-space-12) 0;
  float: left;
}

.froala :global .fr-dii.fr-fir {
  max-width: 66%;
  margin: var(--sk-space-12) 0 var(--sk-space-16) var(--sk-space-12);
  float: right;
}

/* Videos */
.froala :global .fr-video.fr-fvc.fr-dvb {
  display: block;
  text-align: center;
}

.froala :global .fr-video.fr-fvc.fr-dvb.fr-fvl {
  text-align: left;
}

.froala :global .fr-video.fr-fvc.fr-dvb.fr-fvr {
  text-align: right;
}

/* Anchors, Files */
.froala :global .fr-file,
.froala :global a {
  color: var(--sk-color-black);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.froala :global .fr-file:hover,
.froala :global a:hover {
  color: var(--sk-color-black);
  text-underline-offset: 2px;
  text-decoration: underline 2px;
}

.froala :global .fr-file:focus,
.froala :global a:focus {
  outline: thin auto var(--sk-color-grey-500);
  outline-offset: 4px;
  color: var(--sk-color-black);
}

/* Tables */
.froala :global table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  word-break: normal;
}

.froala :global table td {
  padding: var(--sk-space-8);
}

.froala :global table td,
.froala :global table th {
  border: 1px solid var(--sk-color-black);
}

.froala :global table.fr-dashed-borders td,
.froala :global table.fr-dashed-borders th {
  border: 1px dashed var(--sk-color-black);
}

.froala :global table.fr-hidden-borders td,
.froala :global table.fr-hidden-borders th {
  border-color: transparent;
}

/* Headings */
.froala :global h3 {
  margin: var(--sk-space-16) 0 var(--sk-space-16) 0;
  font-size: var(--sk-font-size-5);
  font-weight: var(--sk-font-weights-poppins-3);
  line-height: var(--sk-line-heights-0);
}

.froala :global h2 {
  margin: var(--sk-space-24) 0 var(--sk-space-24) 0;
  font-size: var(--sk-font-size-6);
  font-weight: var(--sk-font-weights-poppins-3);
  line-height: var(--sk-line-heights-7);
}

/* Iframes */
.froala :global iframe {
  max-width: 100%;
}

/* Lists */
.froala :global ul {
  padding-inline-start: var(--sk-space-32);
  list-style: initial;
}

.froala :global ol {
  padding-inline-start: var(--sk-space-32);
}

}