@layer base {
  .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--sk-space-16);
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sk-space-16);
}

.row > * {
  width: 100%;
}

.userStatus {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.userStatus [class*="sk-Text"],
.userStatus [class*="sk-Radio_label"] {
  font-size: var(--sk-font-size-1);
}

.userStatus [class*="sk-Radio_main"] {
  max-height: var(--sk-space-48);
}

.userStatus > :first-child {
  flex: 0 0 100%;
}

.userStatus > :not(:first-child) {
  width: 49%;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-40);
  gap: var(--sk-space-24);
}

.action {
  margin-left: auto;
}

@media (--sk-small-viewport-only) {
  .row {
    grid-template-columns: 1fr;
  }

  .userStatus > :not(:first-child) {
    width: 100%;
  }

  .action {
    width: 100%;
  }
}

}